import { toast } from 'react-toastify';
import { AppDispatch } from '../index';
import API from '@/app/lib/axios';

import { urls } from '../urls';

import { AuthSlice } from '../controllers/AuthSlice';
import { Login, LoginResponse, ResetPassword, Register, ForgotPassword, SetPassword } from '@/shared/models/auth.model';


export const login = (data: Login) => async (dispatch: AppDispatch) => {
  try {
    const URL = urls.login;
    const response: LoginResponse | undefined = await API.post(`${URL}`, data);

    dispatch(AuthSlice.actions.loginSuccess());
    return response;
  } catch (err: any) {
    dispatch(AuthSlice.actions.loginError(err.message));
    toast(`${err.message}`);
    console.error(`Error upon Login: ${err}`);
    throw err;
  }
};

export const logout = () => async () => {
  try {
    const URL = urls.logout;
    await API.post(`${URL}`);
  } catch (err: any) {
    console.error(`Error upon Logout: ${err}`);
  }
};

export const signUp = (data: Register) => async (dispatch: AppDispatch) => {
  try {
    const URL = urls.register;
    await API.post(`${URL}`, data);

    dispatch(AuthSlice.actions.loginSuccess());
  } catch (err: any) {
    dispatch(AuthSlice.actions.loginError(err.message));
    console.error(`Error upon Sign Up: ${err}`);
    throw err;
  }
};

export const forgotPassword = (data: ForgotPassword) => async () => {
  try {
    const URL = urls.forgotPassword;
    await API.post(`${URL}`, data);
  } catch (err: any) {
    console.error(`Forgot Password Sign Up: ${err}`);
    throw err;
  }
};

export const resetPassword = (data: ResetPassword) => async (dispatch: AppDispatch) => {
  try {
    const URL = urls.resetPassword;
    await API.post(`${URL}`, data);

    dispatch(AuthSlice.actions.loginSuccess());
  } catch (err: any) {
    dispatch(AuthSlice.actions.loginError(err.message));
    console.error(`Error upon Sign Up: ${err}`);
    throw err;
  }
};

export const setPassword = (data: SetPassword) => async (dispatch: AppDispatch) => {
  try {
    const { token, u64, password1, password2 } = data;
    const URL = `${urls.setPassword}${u64}/${token}/`;

    const response = await API.post(`${URL}`, { password1, password2 });

    dispatch(AuthSlice.actions.setPasswordSuccess());

    return response;
  } catch (err: any) {
    dispatch(AuthSlice.actions.setPasswordError(err.message));
    toast(`${err.message}`);
    console.error(`Error upon Set Password: ${err}`);
    throw err;
  }
};
