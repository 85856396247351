import { VehicleSettings } from '@/shared/models/vehicle.model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Filters {
  vehicle?: string;
  driver?: string;
}

export type DataState = {
  error: any;
  vehicles: any[];
  currentVehicle: VehicleSettings | null;
  fuels: any[];
  eldConnectionInterface: any[];
  isLoadingVehicles: boolean;
  isLoadingFuels: boolean;
  isLoadingEldConnectionInterface: boolean;
  isRefreshData: boolean;

  dataTotalCount: number;
  dataCurrentCount: number;

  filters?: Filters;
};

const initialState: DataState = {
  error: null,
  vehicles: [],
  currentVehicle: null,
  fuels: [],
  eldConnectionInterface: [],
  isLoadingVehicles: false,
  isLoadingFuels: false,
  isLoadingEldConnectionInterface: false,
  isRefreshData: false,

  dataTotalCount: 0,
  dataCurrentCount: 0,

  filters: {},
};

export const VehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    loadVehicles(state, action: PayloadAction<any>) {
      state.vehicles = action.payload;
    },
    setLoadingVehicles(state, action: PayloadAction<any>) {
      state.isLoadingVehicles = action.payload;
    },

    loadVehicle(state, action: PayloadAction<any>) {
      state.currentVehicle = action.payload;
    },

    loadFuels(state, action: PayloadAction<any>) {
      state.fuels = action.payload;
    },
    setLoadingFuels(state, action: PayloadAction<any>) {
      state.isLoadingFuels = action.payload;
    },

    loadEldConnectionInterface(state, action: PayloadAction<any>) {
      state.eldConnectionInterface = action.payload;
    },
    setLoadingEldConnectionInterface(state, action: PayloadAction<any>) {
      state.isLoadingEldConnectionInterface = action.payload;
    },

    setRefreshData(state, action: PayloadAction<any>) {
      state.isRefreshData = action.payload;
    },

    setTotalCountData(state, action: PayloadAction<any>) {
      state.dataTotalCount = action.payload;
    },
    setCurrentCountData(state, action: PayloadAction<any>) {
      state.dataCurrentCount = action.payload;
    },

    setFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload;
    },
  },
});

export default VehicleSlice.reducer;
