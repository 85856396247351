interface KeyVal {
  [key: string]: string;
}

export const urls: KeyVal = {
  user: 'account/',
  login: 'auth/login/',
  logout: 'auth/logout/',
  register: 'register/',
  forgotPassword: 'auth/forgot_password/',
  resetPassword: 'reset-password/',
  setPassword: 'auth/set_password/',
  users: 'users/',
  userById: 'users/:id',
  drivers: 'drivers/details/',
  createUserDriver: 'drivers/details/create_driver/',
  loadDrivers: 'drivers/latest_statuses/',
  loadSettingsDrivers: 'drivers/details/',
  driverById: 'drivers/details/:id/',
  driverLatestStatusById: 'drivers/latest_statuses/:id/',
  driverInvite: 'drivers/details/:id/send_invite/',
  driverDeactivate: 'drivers/details/:id/deactivate/',
  driverActivate: 'drivers/details/:id/activate/',
  terminals: 'terminals/',
  terminalById: 'terminals/:id/',
  vehicles: 'vehicles/details/',
  vehiclesMap: 'vehicles/latest_statuses/',
  vehiclesLatestStatusById: 'vehicles/latest_statuses/:id',
  vehicleById: 'vehicles/details/:id/',
  vehicleDecodeVin: 'vehicles/details/:code/vin_decode/',
  hosRules: 'common/hos_rules/',
  cargoTypes: 'common/cargo_types/',
  restarts: 'common/restarts/',
  restBreaks: 'common/rest_breaks/',
  fuelTypes: 'common/fuel_types/',
  eldConnectionInterfaces: 'common/eld_connection_interfaces/',
  driverStatuses: 'common/driver_statuses/',
  companies: 'companies/',
  accountCompany: 'account/company/',
  companyById: 'companies/:id/',
  elds: 'elds/',
  logs: 'logs/hos_events/daily_recap/',
  loadLogs: 'logs/hos_events/',
  loadLogsByDateUser: 'logs/hos_events/date/:date/user/:id/',
  loadDailyLogSummary: 'logs/daily_log_summary/',
  loadDriverLogs: 'drivers/daily_logs/:driverId/',
  loadDriverLogEvents: 'logs/hos_events/current_driver/',
  updateLog: 'logs/hos_events/:id/',
  timezone: 'common/timezones/',
  odometerUnits: 'common/odometer_units/',
  driverGroups: '/common/driver_groups/',
  driverLogRecap: '/logs/hos_events/recap/',
  logsByUser: '/logs/hos_events/',
  dailyLogSummaryDetail: 'logs/daily_log_summary/:date/date/',
  dailyLogSummaryByDateUser: 'logs/daily_log_summary/date/:date/user/:user/',
  events: 'logs/events/',
  timeRegulations: '/common/time_regulations/',
  notes: '/common/hos_events_notes/',
};
