import theme, { COLORS } from '@/app/providers/theme';
import styled from '@emotion/styled';
import { Autocomplete, Box, Button, Grid, Link, ListItemIcon, TextField } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const AppBarHeight = 64;
const MainPaddingX = 24;
const MainPaddingY = 16;


export const MainLayoutContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: COLORS.white,
  boxShadow: '0px 8px 40px -10px rgba(0, 0, 0, 0.10)',
  borderRadius: '8px',
  position: 'absolute',
  top: `calc(${AppBarHeight}px + 16px)`,
  left: '24px',
  width: `calc(100% - ${MainPaddingX * 2}px)`,
  height: `calc(100% - ${AppBarHeight + MainPaddingY * 2}px)`,
  padding: '16px',
}));

export const DefaultPageWrapper = styled(Box)(() => ({
  width: '100%',
  overflow: 'auto',
  ['@media print']: {
    overflow: 'initial'
  }
}));

export const GridPageWrapper = styled(DefaultPageWrapper)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
}));

export const CenterContainer = styled(Box)(() => ({
  margin: '0 auto',
  maxWidth: '1200px',
}));

export const FlexBoxRow = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
}));

export const FlexBoxRowStart = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: 20,
}));

export const FlexBoxRowEnd = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 20,
}));

export const FlexBoxColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const EditTextFieldRow = styled(FlexBoxRow)(() => ({
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    display: 'block'
  },
}));

export const EditTextField = styled(TextField)(() => ({
  maxWidth: '297px',
  marginRight: '20px',
  '& legend': {
    display: 'block !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    top: '-5px !important'
  },
  [theme.breakpoints.down('md')]: {
    margin: '0 0 20px',
    maxWidth: '100%',
  },
}));

export const EditAutocomplete = styled(Autocomplete)(() => ({
  [theme.breakpoints.down('md')]: {
    margin: '0 0 20px',
    maxWidth: '100%',
  },
}));

export const BoxContainer = styled(Box)(() => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 8px 40px -10px rgba(0, 0, 0, 0.10)',
  borderRadius: '8px',
  width: '100%',
  padding: '40px 50px',
}));

export const PrimaryButton = styled(Button)(() => ({
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.paper,
  maxWidth: '100%',
  padding: '10px 32px',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: 'initial',
  '&:hover': {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2E87CA',
  },
  '&[disabled]': {
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.background.paper,
  },
  [theme.breakpoints.down('md')]: {
    // padding: '5px 15px',
    // fontSize: 15
  }
}));

export const SecondaryButton = styled(Button)(() => ({
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  maxWidth: '100%',
  padding: '10px 32px',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: 'initial',
  '&:hover': {
    background: `${theme.palette.primary.dark}`,
  },
  '&[disabled]': {
    border: `1px solid ${COLORS.grey}`,
  },
  [theme.breakpoints.down('md')]: {
    // padding: '5px 15px',
    // fontSize: 15
  }
}));

export const MenuLink = styled(NavLink)(() => ({
  color: COLORS.grey,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  textDecoration: 'none',
  minHeight: 48,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__progress-bar {
    background: ${COLORS.primary} !important;
  }
`;

export const EditHeader = styled(FlexBoxRow)(() => ({
  alignItems: 'center',
  marginBottom: '10px',
  [theme.breakpoints.down(800)]: {
    display: 'block',
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'flex-start',
    },
    '& .MuiButtonBase-root': {
      margin: '5px 10px 5px 0',
      padding: '5px 10px',
      fontSize: '13px',
      '&:after': {
        display: 'none',
      }
    },
  },
}));

export const StatusDriverButton = styled(SecondaryButton)(() => ({
  marginRight: '30px',
  '&:after': {
    content: '""',
    position: 'absolute',
    right: '-15px',
    top: 0,
    width: '1px',
    height: '100%',
    background: COLORS.grey2,
  },
}));

export const DeactivateButton = styled(StatusDriverButton)(() => ({
  borderColor: COLORS.red,
  color: COLORS.red,
  '&:hover': {
    background: 'rgba(217, 83, 79, .2)',
  }
}));

export const ActivateButton = styled(StatusDriverButton)(() => ({
  marginRight: '30px',
  borderColor: COLORS.green,
  color: COLORS.green,
  '&:hover': {
    background: 'rgba(76, 175, 80, .2)',
  }
}));